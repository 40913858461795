<template>
  <div>
    <div class="topTitle">
      <div class="rightHref">
        <a class="website" :href="websiteUrl" target="_blank">大奖官网</a>
        <span class="logout" @click="logOut">退出登录</span>  
      </div>
      <p class="title">建筑应用创新大奖评审系统</p>
    </div>
    <div class="userInfo">
      <img class="img" :src="getAvatar(userInfo.avatar)" alt="">
      <Row>
        <Col :md="12" :xs="24">
          <span>尊敬的 {{userInfo.trueName}} 专家，您好！</span>
          <span class="step" v-if="userInfo.competitionUser">{{userInfo.competitionUser.level == "FINAL" ? "终审阶段" : "初审阶段"}}</span>
        </Col>
        <Col :md="12" :xs="24" class="rightText">
          <router-link v-if="getPathName=='ReviewInfo'" class="step href" to="/review/list">返回首页</router-link>
          <span>待评：</span>
          <span class="colorYellow">{{info.reviewing}} </span>
          <span>套作品，已评：</span>
          <span class="colorYellow">{{info.reviewed}} </span>
          <span>套作品</span>
        </Col>
      </Row>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { officialWebsiteUrl } from "@/config/env";
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      websiteUrl: `${officialWebsiteUrl}index`,
      info: {reviewing: 0, reviewed: 0}
    };
  },
  computed:{
    getPathName(){
      return this.$route.name
    }
  },
  methods: {
    // 退出登录
    logOut() {
      this.$store.commit("setToken", "");
      this.$store.commit("setUserInfo", {});
      window.localStorage.removeItem("login_time");
      this.$router.push("/login");
    },
    getAvatar(avatar){
      if(avatar){
        return this.getImageUrl(avatar, "square-small")
      }else{
        return "/img/tx.png"
      }
      
    }
  }
};
</script>

<style lang="scss" scoped>
.topTitle {
  position: relative;
  background: url(/img/bg2.png) no-repeat;
  background-size: cover;
  font-size: 16px;
  height: 130px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #277a44;
    font-family: Source Han Sans CN;
    text-align: center;
    padding-top: 40px;
  }
  .rightHref {
    position: absolute;
    right: 15px;
    top: 15px;
    .website {
      color: #277a44;
      border: 1px solid #277a44;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 15px;

      &:hover {
        color: #fff;
        background: #277a44;
      }
    }
    .logout {
      color: #277a44;
      cursor: pointer;
      padding-right: 14px;
      background: url(/img/logout.png) no-repeat right;
      background-size: 12px;
    }
  }
}
.userInfo{
  margin-top: -10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #46B36C;
  padding: 0 30px 0 80px;
  position: relative;
  color: #fff;
  line-height: 50px;
  .img{
    position: absolute;
    left: 30px;
    top: 5px;
    width: 40px;
    border-radius: 20px;
  }
  .step{
    display: inline-block;
    padding: 2px 5px;
    background: #FB9840;
    border-radius: 5px;
    line-height: 1.5em;
  }
  .href{
    color: #fff;
    margin-right: 10px;
  }
  .rightText{
    text-align: right;
  }
}
@media screen and (max-width:768px) {
.userInfo{
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px 15px 5px 65px;
  .img{
    left: 15px;
  }
  .rightText{
    text-align: left;
  }  
}
}
</style>
